import { ColumnData, TableColumn } from "../../models/Table";
import {formatDate, translate} from "../../Common";

/**
 * Custom filter function for UUID and Enum columns that maps the internal key to their (translated) value.
 * Performs a case-insensitive comparison between mapped value and search value.
 * @param options - schema values from Map
 * @param accessorKey - column / a schema type
 * @param t - Translation function to use
 */
const customUUIDFilter = (options, accessorKey: string, t: Function) => {
    return (row, columnId, filterValue) => {
        // get value from row´s table model
        const value = row.getValue(columnId);
        // map UUID key to value from map
        let translatedValue = options[value] || value;
        // map to translated representation in view if exists
        translatedValue = translate(t, accessorKey, translatedValue);

        // console.log("Search: %s | value: %s | translated: %s", filterValue, value, translatedValue);
        return String(translatedValue).toLowerCase().includes(String(filterValue).toLowerCase());
    };
};

/**
 * Custom filter function for DateTime that maps the internal UTC string to the formatted date.
 * Performs a case-insensitive comparison between mapped value and search value.
 */
const customDateFilter = () => {
    return (row, columnId, filterValue) => {
        const value = formatDate(row.getValue(columnId));

        return String(value).toLowerCase().includes(String(filterValue).toLowerCase());
    };
};

/**
 * Generate table columns based on the provided column data and constraints.
 * @param columnData - schema data
 * @param columnsToExclude - schema data to be excluded
 * @param t - translate function ref
 * @param id - name of the table tab
 */
export const generateColumns = (columnData: ColumnData[], columnsToExclude: string[], t: (key: string) => string, id: string) => {
    const COLUMNS_TO_SHOW: number = id === 'claims' ? 7 : 6;
    const columns: TableColumn[] = [];

    if (columnData.length === 0) {
        return columns;
    }

    // Iterate over each column data entry
    Object.entries(columnData)
        .filter(([key]) => !columnsToExclude.includes(key)) // Exclude specified columns
        .forEach(([key, item], index) => {
            // Create a column definition based on the type of column data
            switch (item.type) {
                case 'Text':
                case 'JSON':
                case 'ARRAY':
                case 'Integer':
                case 'DateTime':
                case 'Float':
                    columns.push({
                        accessorKey: key,
                        header: t('ADMIN.' + key),
                        type: item.type,
                        visibleInShowHideMenu: true,
                        nullable: item.constraints.nullable,
                        maxLength: item.constraints.max_length,
                        isEditable: item.constraints.is_editable,
                        filterFn: item.type === 'DateTime' ? customDateFilter() : undefined // Text, Integer, ARRAY, Float are not mapped in CellRenderer.tsx
                    });
                    break;

                case 'Enum':
                case 'UUID':
                    columns.push({
                        accessorKey: key,
                        header: t('ADMIN.' + key),
                        type: item.type,
                        visibleInShowHideMenu: true,
                        options: item.constraints.options,
                        nullable: item.constraints.nullable,
                        isEditable: item.constraints.is_editable,
                        filterFn: customUUIDFilter(item.constraints.options, key, t) // Custom filter for UUID/Enum
                    });
                    break;

                case 'Boolean':
                    columns.push({
                        accessorKey: key,
                        header: t('ADMIN.' + key),
                        type: item.type,
                        visibleInShowHideMenu: true,
                        nullable: item.constraints.nullable,
                        isEditable: item.constraints.is_editable
                    });
                    break;
            }

            // Hide columns beyond the maximum number allowed to be shown
            if (index >= COLUMNS_TO_SHOW) {
                columns[columns.length - 1].renderColumn = false;
            }
        });

    return columns;
};
