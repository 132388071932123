import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    CircularProgress,
    Divider,
} from '@mui/material';
import { formatDate, translateTable } from "../../Common";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from 'react-i18next';
import useAuthenticatedFetch from "../../auth/authenticated";
import { ClientFromTenant } from "../../models/Claim";

interface LogData {
  timestamp: string;
  user_name: string;
  claim_id: string;
  action: string;
}

const AdminReports = () => {
    const {t} = useTranslation();
    const getAccessHeader = useAuthenticatedFetch();

    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [claimStatus, setClaimStatus] = useState<string | null>('open');
    const [client, setClient] = useState<string | null>(null);
    const [loading, setLoading] = useState({ csv: false, logs: false });
    const [clients, setClients] = useState<ClientFromTenant[]>([]);

    const [logData, setLogData] = useState<LogData[]>([]);
    // Default offset for the number of logs to fetch starts from 0
    const [offset, setOffset] = useState<number>(0);
    // Used to disable the button when there are no more logs to fetch
    const [noMoreData, setNoMoreData] = useState(false);
    // Limit for the number of logs to fetch
    const logLimit: number = 100;

    useEffect(() => {
      const fetchClientsFromTenant = async () => {
          try {
              const response = await fetch('/api/tenant/clients', {
                  method: 'GET',
                  headers: await getAccessHeader(),
              });
  
              if (!response.ok) {
                  console.error('Network response was not ok');
                  return;
              }
  
              const data = await response.json();
              const transformedClients : ClientFromTenant[] = Object.entries(data).map(([id, name]) => ({
                  id: id,
                  name: name as string,
              }));
              
              setClients(transformedClients);
          } catch (error) {
              console.error('Fetch error:', error);
          }
      };

      fetchClientsFromTenant().then();
      // eslint-disable-next-line
  }, []);

    const handleDownloadCSV = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, csv: true }));

        try {
            const response = await fetch('/api/claims/report', {
                method: 'POST',
                headers: await getAccessHeader(),
                body: JSON.stringify({
                    start_date: startDate,
                    end_date: endDate,
                    client_id: client,
                    type_of_claims: claimStatus,
                }),
            });

            if (!response.ok) {
                console.error('Network response was not ok');
            }
            
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'Report_' + claimStatus + '-claims_' + startDate + '_' + endDate + '.csv';
            document.body.appendChild(a);
            a.click();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('There was an error downloading the CSV:', error);
        } finally {
          setLoading((prevLoading) => ({ ...prevLoading, csv: false }));
        }
    };

    const fetchLoggingData = async () => {
      setLoading((prevLoading) => ({ ...prevLoading, logs: true }));

      try {
          const response = await fetch(`/api/logs?limit=${logLimit}&offset=${offset}`, {
              method: 'GET',
              headers: await getAccessHeader(),
          });

          if (!response.ok) {
              console.error('Network response was not ok');
              return;
          }

          setOffset(offset + logLimit);
          const loggingData = await response.json();
          if (loggingData.length === 0) {
              setNoMoreData(true);
          }

            // Apply formatDate to each timestamp in loggingData
          const formattedData = loggingData.map(log => ({
            ...log,
            timestamp: formatDate(log.timestamp),
          }));

          setLogData((prevLogData) => [...prevLogData, ...formattedData]);
        } catch (error) {
          console.error('There was an error fetching the logging data:', error);
        } finally {
          setLoading((prevLoading) => ({ ...prevLoading, logs: false }));
        }
  };

    return (
      <>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5">{t("ADMIN.REPORTS.HEADLINE")}</Typography>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl sx={{ width: "300px" }}>
              <InputLabel>
                {t("ADMIN.REPORTS.SELECT_CLIENT_TO_REPORT")}
              </InputLabel>
              <Select
                variant="standard"
                value={client || ""}
                onChange={(e) => setClient(e.target.value)}
                label={t("ADMIN.REPORTS.SELECT_CLIENT_TO_REPORT")}
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box>
              <TextField
                sx={{ width: "300px", marginRight: "16px" }}
                label={t("ADMIN.REPORTS.START_DATE")}
                type="date"
                value={startDate || ""}
                onChange={(e) => setStartDate(e.target.value)}
                slotProps={{
                    inputLabel: { shrink: true }
                }}
              />
              <TextField
                sx={{ width: "300px" }}
                label={t("ADMIN.REPORTS.END_DATE")}
                type="date"
                value={endDate || ""}
                onChange={(e) => setEndDate(e.target.value)}
                slotProps={{
                    inputLabel: { shrink: true }
                }}
              />
            </Box>

            <ToggleButtonGroup
              value={claimStatus}
              exclusive
              onChange={(event, newStatus) => setClaimStatus(newStatus)}
              aria-label="claim status"
            >
              <ToggleButton value="closed" aria-label="closed claims">
                {t("ADMIN.REPORTS.CLOSED_CLAIMS")}
              </ToggleButton>
              <ToggleButton value="open" aria-label="open claims">
                {t("ADMIN.REPORTS.OPEN_CLAIMS")}
              </ToggleButton>
              <ToggleButton value="all" aria-label="all claims">
                {t("ADMIN.REPORTS.ALL_CLAIMS")}
              </ToggleButton>
            </ToggleButtonGroup>

            {loading.csv ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                sx={{ width: "200px" }}
                variant="contained"
                color="primary"
                onClick={handleDownloadCSV}
                disabled={!startDate || !endDate || !client || !claimStatus}
              >
                {t("ADMIN.REPORTS.DOWNLOAD_CSV")}
              </Button>
            )}
          </Box>
        </Box>
        <Divider></Divider>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5">{t("ADMIN.REPORTS.LOGS")}</Typography>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2, width:'fit-content', alignSelf:'flex-start'}}>
            <MaterialReactTable
                columns={[
                    {
                        accessorKey: 'timestamp',
                        header: t('ADMIN.REPORTS.TIMESTAMP'),
                    },
                    {
                        accessorKey: 'claim_id',
                        header: 'Claim ID',
                    },
                    {
                        accessorKey: 'action',
                        header: t('ADMIN.REPORTS.ACTION'),
                    },
                ]}
                localization={translateTable(t)}
                data={logData}
                enablePagination
                enableSorting
                enableFullScreenToggle={false}
                initialState={{
                  density: 'compact', // Set your default density here
                }}
                muiTableProps={{
                    sx: {
                        marginTop: '16px',
                        maxHeight: '200px', // Set max height for the table
                        overflowY: 'auto',  // Add a scrollbar when content overflows
                        width: 'fit-content', // Adjusts width to the table content
                    },
                }}
            />
            {loading.logs ? (
              <CircularProgress size={24} />
              ) : (
              <Button
                sx={{ width: "200px" }}
                variant="contained"
                color="primary"
                onClick={fetchLoggingData}
                disabled={noMoreData}
              >
              {t("ADMIN.REPORTS.FETCH_LOGS")}
              </Button>
            )}
          </Box>
        </Box>
      </>
    );
};

export default AdminReports;
