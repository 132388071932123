import {
  Box,
  Button,
  CardHeader,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { neutral, primary } from "../../theme/colors";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import AssignToClaimModal from "./modals/AssignToClaimModal";
import AdminMailSpamModal from "./modals/AdminMailSpamModal";
import AdminNewClaimModal from "./modals/AdminNewClaimModal";
import { keyboardArrowIconStyle } from "../../theme/theme";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";
import { ActionMail } from "../../models/Action";

interface AdminMailDetailsMenuProps {
    mailsSchema: any;
    /**
     * Navigation button callback to parent
     */
    onIncrement: () => void;
    /**
     * Navigation button callback to parent
     */
    onDecrement: () => void;
    /**
     * Email amount
     */
    totalEmails: number;
    /**
     * Currently active selected Mail index (Row) from the list
     */
    activeIndex: number;
    /**
     * Model data
     */
    mailDetails: ActionMail;
    /**
     * Loading indicator while new mail data is pending
     */
    isLoadingData: boolean;
    /**
     * API callback
     * @param updatedValue
     */
    updateMailReadStatus: (updatedValue: string) => void;
    /**
     * View update callback
     * @param newStatus
     */
    updateViewWithNewStatus: (newStatus: string) => void;
    /**
     * Callback to show a Feedback message
     * @param success
     */
    triggerFeedback: (success: boolean) => void;
    /**
     * Callback to forward email to user
     */
    forwardEmail: () => void;
}

const AdminMailDetailsMenu: React.FC<AdminMailDetailsMenuProps> = ({
  mailsSchema,
  onDecrement,
  onIncrement,
  totalEmails,
  activeIndex,
  mailDetails,
  isLoadingData,
  updateMailReadStatus,
  updateViewWithNewStatus,
  triggerFeedback,
  forwardEmail
}) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  // state hooks
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [showAssignToClaimModal, setShowAssignToClaimModal] = useState(false);
  const [showSpamModal, setShowSpamModal] = useState(false);
  const [showNewMailModal, setShowNewMailModal] = useState(false);

  const iconStyling = {
    ...keyboardArrowIconStyle,
    fill: mode === "light" ? neutral[900] : neutral[100],
  };

  const handleStatusChange = async (updatedValue: string) => {
    if (updatedValue === "Spam") {
      setShowSpamModal(true);
      return;
    }

    updateMailReadStatus(updatedValue);
  };

  const handleMarkAsSpam = async () => {
    setShowSpamModal(false);
    updateMailReadStatus("Spam");
  };

  return (
    <>
      <CardHeader
        sx={{
          p: "24px",
          width: "100%",
          display: "flex",
          alignSelf: "center",
        }}
        action={
          <Box sx={{ display: "flex" }}>
            <Button
              variant="outlined"
              onClick={forwardEmail}
            >
              {t("ADMIN.MAILS.FORWARD")}
            </Button>
            <Button
              variant="contained"
              sx={{ ml: "16px", color: "white" }}
              onClick={() => setShowNewMailModal(true)}
            >
              {t("ADMIN.MAILS.NEW_CLAIM")}
            </Button>
            <Button
              variant="contained"
              onClick={() => setShowAssignToClaimModal(true)}
              sx={{
                marginLeft: "16px",
                backgroundColor: primary[100],
                color: primary[700],
                "&:hover": {
                  backgroundColor: primary[200],
                  boxShadow: "unset",
                },
              }}
            >
              {t("ADMIN.MAILS.ASSIGN_CLAIM")}
            </Button>
            <FormControl
              variant="standard"
              sx={{ maxWidth: "50%", marginLeft: "16px" }}
            >
              <Select
                variant="standard"
                open={openDropdown}
                onOpen={() => setOpenDropdown(true)}
                onClose={() => setOpenDropdown(false)}
                disableUnderline
                id="status"
                value={mailDetails.read_status || ""}
                onChange={(event) => handleStatusChange(event.target.value)}
                label={t("GENERAL.SELECT_OPTION")}
                inputProps={{ IconComponent: () => null }}
                renderValue={() => (
                  <Chip
                    style={{
                      border: "2px dashed",
                      color: mode === "light" ? neutral[900] : neutral[100],
                      borderColor:
                        mode === "light" ? neutral[900] : neutral[100],
                      paddingTop: "2px",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                    label={
                      <>
                        {t(
                          "ADMIN.MAILS.STATUS." + mailDetails.read_status,
                        )}
                        {openDropdown ? (
                          <KeyboardArrowUpIcon
                            sx={iconStyling}
                            fontSize="large"
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={iconStyling}
                            fontSize="large"
                          />
                        )}
                      </>
                    }
                    variant="outlined"
                  />
                )}
              >
                {mailsSchema.read_status.constraints.options
                  .filter((item) => item !== "Done")
                  .map((item) => (
                    <MenuItem key={item} value={item}>
                      {t("ADMIN.MAILS.STATUS." + item)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <IconButton
              sx={{ mr: 2 }}
              onClick={onDecrement}
              disabled={activeIndex === 0 || isLoadingData}
            >
              <ArrowLeft />
            </IconButton>
            <IconButton
              onClick={onIncrement}
              disabled={activeIndex === totalEmails - 1 || isLoadingData}
            >
              <ArrowRight />
            </IconButton>
          </Box>
        }
        title={
          <Box sx={{ display: "block" }}>
            <Typography variant="h4" sx={{ paddingRight: 2 }}>
              {t("ADMIN.MAILS.MAIL_DETAILS")}
            </Typography>
          </Box>
        }
      />

      {/* Modals */}
      <AssignToClaimModal
        open={showAssignToClaimModal}
        onClose={() => setShowAssignToClaimModal(false)}
        mailId={mailDetails.id}
        mailCounterpart={mailDetails.counterpart}
        updateToNewStatus={updateViewWithNewStatus}
        mailsSchema={mailsSchema}
      />

      <AdminMailSpamModal
        open={showSpamModal}
        onClose={() => setShowSpamModal(false)}
        onMarkAsSpam={handleMarkAsSpam}
      />

      <AdminNewClaimModal
        open={showNewMailModal}
        onClose={() => setShowNewMailModal(false)}
        mailDetails={mailDetails}
        mailsSchema={mailsSchema}
        triggerFeedback={triggerFeedback}
      />
    </>
  );
};

export default AdminMailDetailsMenu;
