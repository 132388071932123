import React, {useState} from 'react';
import {Box, Card, CardContent, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import MailFileUpload from "./MailFileUpload";
import MailCard from "./MailCard";
import {typography} from "../../theme/typography";
import {neutral} from "../../theme/colors";
import {useThemeContext} from "../../theme/ThemeContextProvider";
import {isValidEmail} from "../../Common";
import {Mail} from "../../models/SendMail";

interface MailDetailsProps {
    /**
     * Input data
     */
    mailDetails: Mail;
    /**
     * useState callback to parent
     */
    updateMailDetails: Function;
    /**
     * useState callback to parent
     */
    disableSendMail: Function;
}

const MailDetails: React.FC<MailDetailsProps> = ({mailDetails, updateMailDetails, disableSendMail}) => {

    const [email, setEmail] = useState({
        to: '',
        cc: '',
        errorTo: false,
        errorCc: false
    });
    const { t } = useTranslation();
    const {mode} = useThemeContext();

    const handleMailDetailsChange = (detailName: string, newValue: any) => {
        updateMailDetails(prevState => ({
            ...prevState,
            [detailName]: newValue,
        }));
    }
    const handleEmailChange = (field: 'to' | 'cc') => (value: string) => {
        setEmail({
            ...email,
            [field]: value,
            [field === 'to' ? 'errorTo' : 'errorCc']: !isValidEmail(value)
        });
    };

    const handleKeyDown = (field: 'to' | 'cc') => (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            const chips = field === 'to' ? mailDetails.mailElements.chipsTo : mailDetails.mailElements.chipsCc;
            const input: string = email[field].trim();
            const isValid = isValidEmail(input);

            if (input !== '' && isValid && !chips.includes(input) ) {
                // add recipient/cc
                updateMailDetails(prevState =>({
                    ...prevState,
                    mailElements: {
                        ...prevState.mailElements,
                        [field === 'to' ? 'chipsTo' : 'chipsCc']: [...chips, input],
                    }
                }));

                // clear input field and reset evtl. previous error
                setEmail({
                    ...email,
                    [field]: '',
                    [field === 'to' ? 'errorTo' : 'errorCc']: false
                });
            }
        }
    };

    const handleChipDelete = (field: 'to' | 'cc', chipToDelete: string) => {
        updateMailDetails(prevState => ({
            ...prevState,
            mailElements: {
                ...prevState.mailElements,
                [field === 'to' ? 'chipsTo' : 'chipsCc']: prevState.mailElements[field === 'to' ? 'chipsTo' : 'chipsCc'].filter(chip => chip !== chipToDelete),
            }
        }));
    };

    return (
        <>
            <MailCard
                chips={mailDetails.mailElements.chipsTo ? mailDetails.mailElements.chipsTo : []}
                handleChipDelete={(chip) => handleChipDelete('to', chip)}
                label={t('NEW_MAIL.MAIL_TO')}
                value={email.to}
                onChange={handleEmailChange('to')}
                onKeyDown={handleKeyDown('to')}
                error={email.errorTo}
            />
            <MailCard
                chips={mailDetails.mailElements.chipsCc ? mailDetails.mailElements.chipsCc : []}
                handleChipDelete={(chip) => handleChipDelete('cc', chip)}
                label={t('NEW_MAIL.CC')}
                value={email.cc}
                onChange={handleEmailChange('cc')}
                onKeyDown={handleKeyDown('cc')}
                error={email.errorCc}
            />

            <Card sx={{width:'100%', mb: 3}}>
                <CardContent>
                    <Box>
                        <TextField
                            sx={{
                                '& .MuiInputBase-input': {
                                    ...typography.titleMd,
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottom: !mailDetails.subject ? '1px dashed' : '1px solid',
                                    borderBottomColor: mode === 'light' ? neutral[200] : neutral[600]
                                },
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: mode === 'light' ? neutral[200] : neutral[600],
                                },
                            }}
                            multiline
                            label={t('NEW_MAIL.SUBJECT')}
                            fullWidth
                            variant='standard'
                            value={mailDetails.subject}
                            onChange={(event) => handleMailDetailsChange('subject', event.target.value)}
                            slotProps={{
                                input: {
                                    style: {
                                        fontWeight: 'bold', // Apply bold font weight
                                    },
                                }
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>

            <MailFileUpload onAttachmentChange={handleMailDetailsChange} disableSendMail={disableSendMail} attachments={mailDetails.attachments}/>
        </>
    );
}

export default MailDetails;
