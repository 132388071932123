import React, { useState } from "react";
import {
    Box,
    Button,
    Typography,
    Modal,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { neutral } from "../../../theme/colors";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useAuthenticatedFetch from "../../../auth/authenticated";
import { useNavigate } from 'react-router-dom';
import { modalBoxStyle, modalCardStyle } from "../../../theme/theme";
import {ActionMail} from "../../../models/Action";

interface newClaimData {
    client_id: string;
    counterpart: string;
    carrier_id?: string;
    warehouse_id?: string;
}

interface AdminNewClaimModalProps {
    open: boolean;
    /**
     * Callback function to handle modal close
     * @param event - Event object from the close event
     */
    onClose: (event?: any) => void;
    /**
     * Model data
     */
    mailDetails: ActionMail;
    mailsSchema: any;
    /**
     * Callback to show a Feedback message
     * @param success
     */
    triggerFeedback: (success: boolean) => void;
}

const AdminNewClaimModal: React.FC<AdminNewClaimModalProps> = ({ open, onClose, mailDetails, mailsSchema, triggerFeedback }) => {
    const { t } = useTranslation();
    const { mode } = useThemeContext();
    // state hooks
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedClientId, setSelectedClientId] = useState("");
    const [selectedCarrierId, setSelectedCarrierId] = useState("");
    const [selectedWarehouseId, setSelectedWarehouseId] = useState("");
    const [showError, setShowError] = useState(false);

    const getAccessHeader = useAuthenticatedFetch();
    const navigate = useNavigate();

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        setSelectedClientId(""); // Reset client ID when radio button changes
        setSelectedCarrierId("");
        setSelectedWarehouseId("");
    };

    const resetAndClose = () => {
        setSelectedOption("");
        setSelectedClientId("");
        setSelectedCarrierId("");
        setSelectedWarehouseId("");
        setShowError(false);
        onClose();
    };

    const handleMailUpdate = async () => {
        let data: newClaimData = {
            client_id: selectedClientId,
            counterpart: selectedOption,
        };

        switch (selectedOption) {
            case "CARRIER":
                data.carrier_id = selectedCarrierId;
                break;
            case "WAREHOUSE":
                data.warehouse_id = selectedWarehouseId;
                break;
        }

        try {
            const response = await fetch(`/api/mails/${mailDetails.id}`, {
                method: "PATCH",
                headers: await getAccessHeader(false),
                body: JSON.stringify(data),
            });

            const result = await response.json();

            if(result.success) {
                setShowError(false);
                triggerFeedback(true);

                //POST mail_id to create new claim
                await handleCreatingNewClaim();
            }
            else {
                setShowError(true);
                triggerFeedback(false);

                return result.error;
            }
            resetAndClose(); // Close the modal

        } catch (error: any) {
            console.log("Error:", error.message);
            setShowError(true);
            triggerFeedback(false);

            return `Error`;
        }
    };

    const handleCreatingNewClaim = async () => {
        try {
            const response = await fetch(`/api/claims`, {
                method: "POST",
                headers: await getAccessHeader(false),
                body: JSON.stringify({mail_id: mailDetails.id}),
            });

            if (!response.ok) {
                console.error("Network response was not ok");
                setShowError(true);
                triggerFeedback(false);
            }

            const result = await response.json();
            if (result.claim_id) {
                // Wait a second to show success feedback and navigate to new claim
                setTimeout(() => {
                    navigate(`/claims/${result.claim_id}`);
                }, 1000);
            } else {
                // Handle unexpected response structure
                triggerFeedback(false);
                setShowError(true);
            }

        } catch (error) {
            console.error("Error updating mail:", error);
            setShowError(true);
            triggerFeedback(false);
        }
    }


    return mailsSchema ? (
        <Modal open={open} onClose={resetAndClose}>
            <Box sx={modalBoxStyle}>
                <Card sx={modalCardStyle}>
                    <CardHeader
                        sx={{ paddingBottom: "8px" }}
                        title={<Typography variant="h5">{t("ADMIN.MAILS.NEW_CLAIM")}</Typography>}
                        action={
                            <IconButton onClick={resetAndClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                    ></CardHeader>

                    <CardContent>
                        <FormControl>
                            <Typography>{t("ADMIN.MAILS.SELECT_COUNTERPART")}</Typography>
                            <RadioGroup
                                aria-label="options"
                                name="options"
                                value={selectedOption}
                                onChange={handleRadioChange}
                            >
                                <Grid container spacing={3}>
                                    {mailsSchema.counterpart.constraints.options
                                        .filter((option: string) => option !== "UNKNOWN")
                                        .map((option: string) => (
                                            <FormControlLabel
                                                key={option}
                                                value={option}
                                                control={<Radio />}
                                                label={t("ADMIN.MAIL_TYPE." + option)}
                                            />
                                        ))}
                                </Grid>
                            </RadioGroup>
                        </FormControl>

                        {selectedOption !== "" && (
                            <Box sx={{ mt: 3 }}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="Client">{t("ADMIN.MAILS.SELECT_CLIENT")}</InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId="Client"
                                        value={selectedClientId}
                                        onChange={(event) =>
                                            setSelectedClientId(event.target.value)
                                        }
                                        IconComponent={ArrowDownIcon}
                                    >
                                        {Object.entries(mailsSchema?.client_id.constraints.options).map(
                                            ([key, item]) => (
                                                <MenuItem key={key} value={key}>
                                                    {item as string}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        {selectedOption === "CARRIER" && (
                            <Box sx={{ mt: 3 }}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="Carrier">{t("ADMIN.MAILS.SELECT_CARRIER")}</InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId="Carrier"
                                        value={selectedCarrierId}
                                        onChange={(event) =>
                                            setSelectedCarrierId(event.target.value)
                                        }
                                        IconComponent={ArrowDownIcon}
                                    >
                                        {Object.entries(mailsSchema?.carrier_id.constraints.options).map(
                                            ([key, item]) => (
                                                <MenuItem key={key} value={key}>
                                                    {item as string}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        {selectedOption === "WAREHOUSE" && (
                            <Box sx={{ mt: 3 }}>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="Warehouse">{t("ADMIN.MAILS.SELECT_WAREHOUSE")}</InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId="Warehouse"
                                        defaultValue={''}
                                        value={selectedWarehouseId}
                                        onChange={(event) =>
                                            setSelectedWarehouseId(event.target.value)
                                        }
                                        IconComponent={ArrowDownIcon}
                                    >
                                        {Object.entries(mailsSchema?.warehouse_id.constraints.options).map(
                                            ([key, item]) => (
                                                <MenuItem key={key} value={key}>
                                                    {item as string}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: 4,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    width: "267px",
                                    height: "48px",
                                    color: mode === "light" ? neutral[700] : neutral[200],
                                }}
                                onClick={resetAndClose}
                            >
                                {t("GENERAL.CANCEL")}
                            </Button>
                            <Button
                                variant="contained"
                                tabIndex={7}
                                sx={{
                                    width: "267px",
                                    height: "48px"
                                }}
                                onClick={handleMailUpdate}
                                disabled={
                                    (selectedOption === "CLIENT" && !selectedClientId) ||
                                    (selectedOption === "CARRIER" && (!selectedClientId || !selectedCarrierId)) ||
                                    (selectedOption === "WAREHOUSE" && (!selectedClientId || !selectedWarehouseId)) ||
                                    selectedOption === ""
                                }
                            >
                                {t("ADMIN.MAILS.CREATE_NEW_CLAIM")}
                            </Button>
                        </Box>
                    </CardContent>
                    { showError && <Typography>{t("GENERAL.ERROR")}</Typography>}
                </Card>
            </Box>
        </Modal>
    ) : null;
};

export default AdminNewClaimModal;
