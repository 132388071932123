import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { common, danger, neutral } from "../../../theme/colors";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../../../theme/ThemeContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import { keyboardArrowIconStyle, modalBoxStyle, modalCardStyle } from "../../../theme/theme";
import useAuthenticatedFetch from "../../../auth/authenticated";

// readonly constants
const MAX_DAYS: number = 365;
const WAITING_DAYS: number = 30;

interface WaitingModalProps {
    isOpen: boolean;
    /**
     * Callback function to handle modal close
     * @param event - Event object from the close event
     */
    onClose: (event?: any) => void;
    /**
     * Callback function to handle save
     * @param claimStatusId
     * @param wakeupTime
     * @param processType
     */
    onSave: (claimStatusId: string, wakeupTime: string, processType: string) => void;
    waitingStatusSchema: any;
}

const WaitingModal: React.FC<WaitingModalProps> = ({ isOpen, onClose, onSave, waitingStatusSchema }) => {
  const getAccessHeader = useAuthenticatedFetch();
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [waitingDays, setWaitingDays] = useState<number>(WAITING_DAYS);
  const [processType, setProcessType] = useState<string>("auto");
  const [waitingStatusOptions, setWaitingStatusOptions] = useState<string[]>(
    [],
  );

  useEffect(() => {
    const fetchWaitingStatusSchema = async () => {
      try {
        const response = await fetch("/api/claims/statuses&states=WAITING", {
          method: "GET",
          headers: await getAccessHeader(),
        });

        if (response.ok) {
          response.json().then((result) => {
            setWaitingStatusOptions(Object.keys(result));
          });
        } else {
          console.error("Network response was not ok");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isOpen) {
      fetchWaitingStatusSchema().then();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const calculateUTCDate = (waitingDays: number): string => {
    // Get the current date
    const currentDate = new Date();

    // Add waitingDays to the current date
    const futureDate = new Date(
      currentDate.getTime() + waitingDays * 24 * 60 * 60 * 1000,
    );

    // Convert the future date to UTC format
    return futureDate.toISOString();
  };

  const handleDaysChange = (val: string) => {
    val = val.trim();
    const days = parseInt(val);
    setWaitingDays(days > MAX_DAYS ? MAX_DAYS : days);
  };

  const handleSave = () => {
    resetAndClose();
    onSave(selectedValue, calculateUTCDate(waitingDays), processType);
  };

  const resetAndClose = () => {
      setSelectedValue('');
      setWaitingDays(WAITING_DAYS);
      setProcessType('auto');
      onClose();
  }

  return (
    <Modal open={isOpen} onClose={resetAndClose}>
      <Box sx={modalBoxStyle}>
        <Card sx={modalCardStyle}>
          <CardHeader
            sx={{ paddingBottom: "8px" }}
            title={
              <Typography variant="h5">
                {t("WAITING_TIME_MODAL.TITLE")}
              </Typography>
            }
            action={
              <IconButton onClick={resetAndClose}>
                <CloseIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            <Box sx={{ marginBottom: "24px" }}>
              <Typography variant="bodyMd">
                {t("WAITING_TIME_MODAL.SUB_TITLE")}
              </Typography>
            </Box>

            <TextField
              id="waiting-days"
              label={t("WAITING_TIME_MODAL.WAITING_DAYS")}
              value={waitingDays}
              sx={{ width: "100px" }}
              type="number"
              variant="standard"
              required
              onChange={(e) => handleDaysChange(e.target.value)}
              slotProps={{
                htmlInput: {
                  min: "1",
                  max: MAX_DAYS.toString()
                },

                inputLabel: {
                  shrink: true,
                }
              }}
            />

            <FormControl
              variant="standard"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
              }}
            >
              <Typography variant="titleLg">
                {t("WAITING_TIME_MODAL.CHOOSE_STATUS")}
              </Typography>
              <Select
                variant="standard"
                open={openDropdown}
                onOpen={() => setOpenDropdown(true)}
                onClose={() => setOpenDropdown(false)}
                sx={{
                  marginTop: "8px",
                  padding: "16px",
                  backgroundColor: danger[600],
                  textAlign: "center",
                }}
                disableUnderline
                value={selectedValue}
                displayEmpty={true}
                onChange={(event) =>
                  setSelectedValue(event.target.value as string)
                }
                inputProps={{ IconComponent: () => null }}
                renderValue={() => (
                  <Chip
                    style={{
                      border: "2px dashed",
                      color: common["white"],
                      borderColor: common["white"],
                      paddingTop: "2px",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                    label={
                      <>
                        {selectedValue === ""
                          ? t("GENERAL.SELECT_OPTION")
                          : t("STATUS." + waitingStatusSchema[selectedValue])}
                        {openDropdown ? (
                          <KeyboardArrowUpIcon
                            sx={keyboardArrowIconStyle}
                            fontSize="large"
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            sx={keyboardArrowIconStyle}
                            fontSize="large"
                          />
                        )}
                      </>
                    }
                    variant="outlined"
                  />
                )}
              >
                {waitingStatusOptions.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t("STATUS." + waitingStatusSchema[item])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

              <Typography variant="titleLg" sx={{mt: "25px", display: "block"}}>
                  {t("WAITING_TIME_MODAL.CHOOSE_PROCESSING")}
              </Typography>

              <FormControl>
                  <RadioGroup row value={processType} onChange={(event) => setProcessType(event.target.value)}>
                    <FormControlLabel value={'auto'} control={<Radio />} label={t('WAITING_TIME_MODAL.AUTOMATIC')} />
                    <FormControlLabel value={'manual'} control={<Radio />} label={t('WAITING_TIME_MODAL.MANUAL')} />
                  </RadioGroup>
              </FormControl>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 4,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: "267px",
                  height: "48px",
                  color: mode === "light" ? neutral[700] : neutral[200],
                }}
                onClick={resetAndClose}
              >
                {t("GENERAL.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                    width: "267px",
                    height: "48px"
                    }}
                onClick={handleSave}
                disabled={
                  selectedValue === "" ||
                  isNaN(waitingDays) ||
                  waitingDays === 0
                }
              >
                {t("GENERAL.SAVE")}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default WaitingModal;
