import { Button, CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CarrierIcon } from '../assets/table/carrier.svg';
import { ReactComponent as ClientIcon } from '../assets/table/client.svg';
import { ReactComponent as ClaimsIcon } from '../assets/table/inbox-in.svg';
import { ReactComponent as MailIcon } from '../assets/table/mail.svg';
import { ReactComponent as TenantIcon } from '../assets/table/tenant.svg';
import { ReactComponent as UserIcon } from '../assets/table/user.svg';
import { ReactComponent as WarehouseIcon } from '../assets/table/warehouse.svg';
import { ReactComponent as ReportIcon } from '../assets/table/report.svg';
import { ReactComponent as SettingsIcon } from '../assets/table/settings.svg';
import useAuthenticatedFetch from "../auth/authenticated";
import AdminMail from "../components/adminMail/AdminMail";
import { ColumnData, rowData } from "../models/Table";
import Table from "../components/table/ReactMaterialTable";
import { User } from "../models/User";
import { neutral, primary } from "../theme/colors";
import theme from "../theme/MuiTheme";
import AdminReports from "../components/adminReports/AdminReports";

interface TabData {
    label: string;
    endpoint: string;
    id: string;
    icon: any;
    tabOrder: number;
}

interface TabPerRole {
    role: string;
    ids: string[];
}

interface DashboardAdminProps {
    user: User;
}

/**
 * Returns an ordered Tab Data model based on user roles.
 * @param user
 */
const defineViewTabsByUserRoles = (user: User) : TabData[] => {
    // data pool
    const pool: TabData[] = [
        { label: 'Tenants', endpoint: '/api/tenants', id: 'tenants', icon: <TenantIcon />, tabOrder: 0 }, // for super admin only available
        { label: 'Mail templates', endpoint: '/api/mail_templates', id: 'mails', icon: <MailIcon />, tabOrder: 1 },
        { label: 'Users', endpoint: '/api/users', id: 'users', icon: <UserIcon />, tabOrder: 2 },
        { label: 'Carriers', endpoint: '/api/carriers', id: 'carriers', icon: <CarrierIcon />, tabOrder: 3 },
        { label: 'Clients', endpoint: '/api/clients', id: 'clients', icon: <ClientIcon />, tabOrder: 4 },
        { label: 'Warehouses', endpoint: '/api/warehouses', id: 'warehouses', icon: <WarehouseIcon />, tabOrder: 5 },
        { label: 'Claims', endpoint: '/api/claims', id: 'claims', icon: <ClaimsIcon />, tabOrder: 6 },
        { label: 'Settings', endpoint: '/api/settings', id: 'settings', icon: <SettingsIcon />, tabOrder: 7 }
    ];

    // UX / BE defined allowed views
    const viewRights: TabPerRole[] = [
        { role: 'superadmin', ids: ['tenants', 'users'] },
        { role: 'admin', ids: ['mails', 'users', 'carriers', 'clients', 'warehouses', 'settings'] },
        { role: 'operator', ids: ['claims'] },
    ];

    const ids: string[] = [];
    user.roles.forEach( (role: string) => {
        const rights: TabPerRole | undefined = viewRights.find( x => role === x.role);
        if (rights) {
            rights.ids.forEach( y => !ids.includes(y) ? ids.push(y) : null);
        }
    });

    const tabData: TabData[] = [];
    ids.forEach( (x) => {
        const tab: TabData  | undefined = pool.find( p => p.id === x);
        if (tab) {
            tabData.push(tab);
        }
    });

    return tabData.sort((a, b) => a.tabOrder - b.tabOrder);
}

// session storage readonly keys
const SS_KEY: string = "tabId";
const MAILBOX_ID: number = 99;
const REPORTS_ID: number = 100;

// data fetch limit
const DATA_PER_PAGE: number = 100;
const START_PAGE_INDEX: number = 1;

const DashboardAdmin: React.FC<DashboardAdminProps> = ({user}) => {
    // model
    const tabData: TabData[] = defineViewTabsByUserRoles(user);

    // case: page reload - load last view / selected tab
    const idx: string | null = sessionStorage.getItem(SS_KEY);
    let lastActiveIdx: number = 0;
    if (idx) {
        lastActiveIdx = JSON.parse(idx);
    }

    // hooks
    const { t } = useTranslation();
    const getAccessHeader = useAuthenticatedFetch();

    // initial loading related
    const [isPending, setIsPending] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    // data and schema data
    const [data, setData] = useState<rowData[]>([]);
    const [columnData, setColumnData] = useState<ColumnData[]>([]);
    // possible views
    const [selectedTab, setSelectedTab] = useState<any>(lastActiveIdx < tabData.length ? lastActiveIdx : false);
    const [showMailsView, setShowMailsView] = useState(lastActiveIdx === MAILBOX_ID);
    const [showReportsView, setReportsView] = useState(lastActiveIdx === REPORTS_ID);
    // pagination related
    const [page, setPage] = useState(START_PAGE_INDEX);
    const [totalCount, setTotalCount] = useState(0);
    const [nextPagePending, setNextPagePending] = useState(false);

    const getButtonStyles = (isActive: boolean) => ({
        backgroundColor: 'transparent',
        marginLeft: '16px',
        color: isActive
            ? (theme.palette.mode === 'light' ? primary[500] : primary[600])
            : neutral[300],
        borderColor: isActive
            ? (theme.palette.mode === 'light' ? primary[500] : primary[600])
            : neutral[300],
        '&:hover': {
            borderColor: isActive
                ? (theme.palette.mode === 'light' ? primary[700] : primary[700])
                : neutral[200],
            color: isActive
                ? (theme.palette.mode === 'light' ? primary[700] : primary[700])
                : neutral[600],
            backgroundColor: 'transparent',
        },
    });

    /**
     * On a Tab change or on first load new data and schema for the table is needed.
     */
    useEffect(() => {
        // Mails or Reports view will fetch its own data
        if (selectedTab === false) {
            return;
        }

        fetchDataAndSchema().then();
        // eslint-disable-next-line
    }, [selectedTab]);

    /**
     * Fetches the data schema for the selected Tab.
     */
    const fetchSchema = async () => {
        const schemaEndpoint = tabData[selectedTab].endpoint + `/schema`;
        const columnResponse = await fetch(schemaEndpoint, {
                method: 'GET',
                headers: await getAccessHeader(false),
        });

        if (!columnResponse.ok) {
            console.error('Failed to fetch schema data.');
            setIsPending(false);
            setFetchError(true);

            return;
        }

        const columnResult = await columnResponse.json();
        setColumnData(columnResult);
    }

    /**
     * Fetches partial data for the selected Tab in a paginated way.
     * Reason: To reduce Server load on heavy requests like for the claims table.
     */
    const fetchData = async () => {
        const dataEndpoint = `${tabData[selectedTab].endpoint}?page=${page}&page_size=${DATA_PER_PAGE}`;
        const dataResponse = await fetch(dataEndpoint, {
                method: 'GET',
                headers: await getAccessHeader(false),
        });

        if (!dataResponse.ok) {
            console.error('Failed to fetch data.');
            setIsPending(false);
            setFetchError(true);

            return;
        }

        const dataResult = await dataResponse.json();
        // total amount of data
        setTotalCount(dataResult.total_rows);
        // add new "data page" to existing data set
        setData( (prevData) => [...prevData, ...dataResult.entries]);
        // raise index for next "data page" fetch
        setPage(page + 1);
    };

    /**
     * Fetches schema and the according data for the table type
     */
    const fetchDataAndSchema = async () => {
        setIsPending(true);
        setFetchError(false);

        await Promise.all([
            fetchSchema(),
            fetchData()
        ]);

        setIsPending(false);
    }

    /**
     * Click handler for the next page button.
     */
    const handleNextPageLoad = () => {
        setNextPagePending(true);
        fetchData().then(() => setNextPagePending(false));
    }

    /**
     * Click handler for the Mails View button.
     */
    const handleMailsView = () => {
        setShowMailsView(true);
        setReportsView(false);
        // unselect the tab
        setSelectedTab(false);
        sessionStorage.setItem(SS_KEY, MAILBOX_ID.toString());
    }

    /**
     * Click handler for the Tab selection
     * @param event
     * @param newValue
     */
    const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) => {
        setShowMailsView(false);
        setReportsView(false);
        // select the tab
        setSelectedTab(newValue);
        sessionStorage.setItem(SS_KEY, newValue.toString());
        // reset the table data and page index
        setPage(START_PAGE_INDEX);
        setData([]);
    };

    /**
     * Click handler for the Reports View button.
     */
    const handleReportsView = () => {
        setShowMailsView(false);
        setReportsView(true);
        // unselect the tab
        setSelectedTab(false);
        sessionStorage.setItem(SS_KEY, REPORTS_ID.toString());
    }

    return (
        <Box sx={{ width: '100%', marginBottom: "96px" }}>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="wrapper">
                    {/* Table View Tabs */}
                    <Tabs value={selectedTab}
                          onChange={handleChange}
                          aria-label="tabs"
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          selectionFollowsFocus
                          sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {tabData.map((tab, index) => (
                            <Tab key={index}
                                label={tab.label}
                                icon={tab.icon}
                                iconPosition="start"
                                disabled={isPending && index !== selectedTab && !fetchError} />
                        ))}
                    </Tabs>
                    {/* Admin has two more views / buttons */}
                    { user.isAdmin &&
                        <Box sx={{ display: "inline-flex"}}>
                            <Button
                                sx={getButtonStyles(showReportsView)}
                                variant="outlined"
                                startIcon={<ReportIcon />}
                                onClick={handleReportsView}
                                size="small"
                            >
                                {t('ADMIN.REPORTS.HEADLINE')}
                            </Button>
                            <Button
                                sx={getButtonStyles(showMailsView)}
                                variant="outlined"
                                onClick={handleMailsView}
                                startIcon={<MailIcon />}
                                size="small"
                            >
                                {t('ADMIN.MAILS.BUTTON_LABEL')}
                            </Button>
                        </Box>
                    }
                </Box>

                {/* Initial Request / Table data loading */}
                { isPending ?
                    <Box sx={{ height: '100%', width: '100%', mt: '100px', textAlign: 'center' }}>
                        <CircularProgress />
                    </Box>
                    :
                    // Request finished
                    <Box className="wrapper" sx={{ paddingTop: "16px" }}>
                        {/* Fetch error */}
                        { fetchError ? <Box sx={{ m: 5, textAlign: 'center' }}>{t("ADMIN.NO_DATA")}</Box>
                                     : <>
                                        {/* Admin additional view was selected? */}
                                        {showMailsView ? <AdminMail /> :
                                            showReportsView ? <AdminReports /> :
                                                    // Render table for selected Tab
                                                    <>
                                                        <Table initialData={data}
                                                               columnData={columnData}
                                                               endpoint={tabData[selectedTab].endpoint}
                                                               showCreateNewButton={tabData[selectedTab].id !== 'claims'}
                                                               showExtraActions={tabData[selectedTab].id === 'claims'}
                                                               id={tabData[selectedTab].id}></Table>
                                                        {/* Next page fetching indicator or button */}
                                                        { nextPagePending ? <CircularProgress sx={{ marginLeft: "auto", marginTop: "16px", display: "block" }} /> :
                                                                        <Button
                                                                            sx={{ marginLeft: "auto", marginTop: "16px", display: "block" }}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={handleNextPageLoad}
                                                                            disabled={data.length === totalCount}
                                                                        >
                                                                            {t("ADMIN.GET_NEXT_DATA", {shown: data.length, total: totalCount})}
                                                                        </Button>
                                                        }
                                                    </>
                                        }
                                       </>

                        }
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default DashboardAdmin;
