import { Box, Card, IconButton, Tooltip } from "@mui/material";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from "material-react-table";
import { common, neutral, primary } from "../../theme/colors";
import { useThemeContext } from "../../theme/ThemeContextProvider";
import React, { useMemo } from "react";
import {formatDate, translateTable} from "../../Common";
import { MailSearchResult } from "../../models/MailSearchResult";
import { useTranslation } from "react-i18next";
import { ReactComponent as LaunchIcon } from "../../assets/table/external-link.svg";
import { mailListTableWrapperStyle } from "../../theme/theme";

const tableCellStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "150px",
};

interface SearchMailListProps {
  /**
   * Input data
   */
  mailsData: MailSearchResult[];
  /**
   * Currently active selected Mail index (Row) from the list
   */
  selectedRowId: string;
  /**
   * useState callback to parent
   */
  onSelectRow: (email: MailSearchResult) => void;
}

const SearchMailList: React.FC<SearchMailListProps> = ({ mailsData, selectedRowId, onSelectRow }) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();

  const tableBackgroundStyle = {
    backgroundColor: mode === "light" ? common["white"] : neutral[900],
  };

  const columns = useMemo<MRT_ColumnDef<MailSearchResult>[]>(
    () => [
      {
        accessorKey: "from_address",
        header: t("TABLE.FROM"),
        size: 20,
      },
      {
        accessorKey: "timestamp",
        header: t("TABLE.DATE"),
        size: 50,
        Cell: ({ cell }) => formatDate(cell.getValue() as string, false), // Format date using formatDate function
      },
      {
        accessorKey: "subject",
        header: t("TABLE.SUBJECT"),
        size: 100,
      },
      {
        accessorKey: "claimId",
        header: t("TABLE.CLAIM"),
        size: 50,
        Cell: ({ cell }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip title={t("TABLE.OPEN_CLAIM_VIEW")}>
              <IconButton onClick={() => handleOpenClaimView(cell.getValue())}>
                <LaunchIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [t],
  );

  const handleRowClick = (row: any) => {
    const newRowId = selectedRowId === row.id ? null : row.id;
    if (newRowId) {
      const selectedEmail = mailsData.find((mail) => mail.id === newRowId);
      if (selectedEmail) {
        onSelectRow(selectedEmail);
      }
    }
  };

  const handleOpenClaimView = (claimId) => {
    window.open("/claims/" + claimId, "_blank");
  };

  const table = useMaterialReactTable({
    columns,
    data: mailsData,
    initialState: { pagination: { pageSize: 100, pageIndex: 0 }},
    enableGlobalFilter: true,
    enablePagination: true,
    enableStickyHeader: true,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleRowClick(row),
      tabIndex: 0, // Make the row focusable
      sx: {
        cursor: "pointer",
        boxShadow: "unset",
        "&:hover, &:focus": {
          backgroundColor: mode === "light" ? primary[200] : primary[800],
          outline: "none",
        },
        backgroundColor:
          selectedRowId.toString() === row.id
            ? mode === "light"
              ? neutral[100]
              : neutral[900]
            : "inherit",
        outline: "none",
      },
    }),
    defaultColumn: { // applies to all columns
      muiTableBodyCellProps: {
        sx: {
          ...tableCellStyles,
        },
      },
      muiTableHeadCellProps: {
        sx: {
          ...tableBackgroundStyle,
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
        ...tableBackgroundStyle,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        ...tableBackgroundStyle,
      },
    },
    muiTopToolbarProps: {
      sx: {
        ...tableBackgroundStyle,
      },
    },
    localization: translateTable(t),
  });

  return (
    <Card sx={mailListTableWrapperStyle}>
      <MaterialReactTable table={table} />
    </Card>
  );
};

export default SearchMailList;
