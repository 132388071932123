import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuthenticatedFetch from "../auth/authenticated";

interface DebugClaim {
  id: string;
  status: string;
}

const ClaimsList = () => {
  const [claimsData, setClaimsData] = useState<DebugClaim[]>([]);
  const [claimSchema, setClaimSchema] = useState<any>();

  const { t } = useTranslation();
  const getAccessHeader = useAuthenticatedFetch();

  useEffect(() => {
    const fetchClaims = async () => {
      fetch(`/api/claims?page=1&page_size=10000`, {
        method: "GET",
        headers: await getAccessHeader(false),
      })
        .then((response) => response.json())
        .then((data) => {
          setClaimsData(parseDebugData(data));
        })
        .catch((error) => {
          console.error("Error fetching claims:", error);
        });
    };

    const fetchClaimSchema = async () => {
      const url = `/api/claims/schema`;

      fetch(url, {
        method: "GET",
        headers: await getAccessHeader(false),
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setClaimSchema(data);
        })
        .catch((error) => {
          console.error("Error fetching claim schema data:", error);
        });
    };

    const fetchData = async () => {
      await fetchClaimSchema();
      await fetchClaims();
    };

    fetchData().then();
    // eslint-disable-next-line
  }, []);

  const parseDebugData = (response: any): DebugClaim[] => {
    const c: DebugClaim[] = [];
    response.entries.forEach((x: any) => {
      c.push({
        id: x.entry.id,
        status: x.entry.claim_status_id,
      });
    });

    return c;
  };

  return (
    <Box className="wrapper">
      <Typography variant="h2">
        {t("CLAIMS_LIST") + ": " + claimsData.length}
      </Typography>

      {claimsData.length === 0 || !claimSchema ? (
        <CircularProgress sx={{ ml: 10, mt: 10 }} />
      ) : (
        <List>
          {claimsData.map((c: DebugClaim, index: number) => (
            <ListItem key={c.id} sx={{ border: "1px dashed grey", mt: 1 }}>
              <ListItemText primary={index} />
              <ListItemButton component="a" href={`/claims/${c.id}`}>
                <ListItemText primary={c.id} />
              </ListItemButton>
              <ListItemText
                primary={
                  "Claim Status ID: " +
                  t(
                    "STATUS." +
                      claimSchema.claim_status_id.constraints.options[c.status],
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ClaimsList;
