import React, { useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  IconButton,
  Tooltip
} from '@mui/material';
import TextField from "@mui/material/TextField";
import {ReactComponent as CheckmarkIcon} from '../../assets/checkmark.svg';
import {ReactComponent as CloseIcon} from '../../assets/close.svg';
import {ReactComponent as ErrorIcon} from '../../assets/error.svg';
import {ReactComponent as CopyIcon} from '../../assets/copy.svg';
import {ReactComponent as PasteIcon} from '../../assets/paste.svg';
import {danger, neutral} from "../../theme/colors";
import {useThemeContext} from "../../theme/ThemeContextProvider";
import { useTranslation } from "react-i18next";
import {typography} from "../../theme/typography";

interface CustomTextFieldProps {
  label: string;
  initialValue?: string;
  onSave?: (value: string) => string | Promise<string>;
  isRequired?: boolean;
  width?: string;
  marginBottom?: string;
  marginRight?: string;
  multiline?: boolean;
  maxRows?: number | undefined;
  regex?: RegExp;
  isUppercase?: boolean;
  isEditable?: boolean;
  isArray?: boolean;
  useSpellCheck?: boolean;
}

const InputField: React.FC<CustomTextFieldProps> = ({
                                                      label,
                                                      initialValue = '',
                                                      onSave,
                                                      isRequired = false,
                                                      width = '100%',
                                                      marginBottom = '0px',
                                                      marginRight = '0px',
                                                      multiline = true,
                                                      maxRows = undefined,
                                                      regex = null,
                                                      isUppercase = false,
                                                      isEditable = true,
                                                      isArray = false,
                                                      useSpellCheck = false
                                                    }) => {

  const mb: string = '25px';
  const {mode} = useThemeContext();

  const {t} = useTranslation();
  const [value, setValue] = useState(initialValue);
  const [prevValue, setPrevValue] = useState(initialValue);
  const [saveButton, setSaveButton] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState<any>('');
  const [inputError, setInputError] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [isSaving, setIsSaving] = useState(false);


  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handlePaste = async () => {
    let pastedText: string = await navigator.clipboard.readText();
    pastedText = pastedText.trim();

    if (isUppercase) {
      pastedText = pastedText.toUpperCase();
    }

    if (isValid(pastedText)) {
      setSaveButton(true);
      setValue(pastedText);
    }
  };

  const isValid = (txt: string): boolean => {
    if (value === txt) {
      return false;
    }
    
    let valuesToCheck: string[];
  
    if (isArray) {
      valuesToCheck = txt.split(',').map(val => val.trim()).filter(Boolean); //Kicks out empty strings from the array
    } else {
      valuesToCheck = [txt];
    }

    for (let currentValue of valuesToCheck) {
      if (currentValue !== '' && regex && !regex.test(currentValue)) {
        setInputErrorMessage(t('GENERAL.INVALID_INPUT'));
        setInputError(true);
        return false;
      }
    }

    setInputErrorMessage('');
    setInputError(false);
    return true;
  }

  const handleChange = (e) => {
    let val = e.target.value;

    if (isUppercase) {
      val = val.toUpperCase();
    }

    if (isValid(val)) {
      setSaveButton(true);
      setValue(val);
    }
  };

  const handleCancel = () => {
    setValue(prevValue);
    setSaveButton(false);

    setInputErrorMessage('')
    setInputError(false);
  }

  const handleSave = async () => {
    if (value !== prevValue && onSave) {
      setIsSaving(true);
      const saveResult = await onSave(value);

      if (saveResult === 'Successful') {
        setPrevValue(value);
        setSaveButton(false);
      } else {
        setInputErrorMessage(saveResult); // TODO: This might be replaced by t('GENERAL.INVALID_INPUT') instead of BE message?
        setInputError(true);
      }

      setIsSaving(false);
    } else {
      setValue(prevValue);
      setSaveButton(false);
    }
  }

  return (
      <>
        <TextField
            style={{ width, marginBottom, marginRight}}
            sx={{
              '& .MuiInputBase-input': {
                ...typography.titleMd,
              },
              '& .MuiInput-underline:before': {
                borderBottom: !value ? '1px dashed' : '1px solid',
                borderBottomColor: isRequired && !value ? danger[500]
                    : mode === 'light' ? neutral[200] : neutral[600]
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottomColor: mode === 'light' ? neutral[200] : neutral[600],
              },
            }}
            spellCheck={useSpellCheck}
            label={label}
            disabled={isSaving || !isEditable}
            multiline={multiline}
            maxRows={multiline && maxRows ? maxRows : undefined}
            required={isRequired}
            variant="standard"
            value={value}
            onChange={handleChange}
            helperText={inputErrorMessage}
            error={inputError}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            slotProps={{
              input: {
                sx: {alignItems: 'flex-end'},
                endAdornment: (isHovered || value !== prevValue) && (
                    <InputAdornment position="end">
                      {saveButton ? (
                          <>
                            {isSaving ? <CircularProgress style={{marginBottom: mb, marginRight: '5px'}} size={20}/>
                                :
                                (
                                    <div style={{marginBottom: mb}}>
                                      {inputError ? (
                                          <IconButton disabled size="small">
                                            <ErrorIcon/>
                                          </IconButton>
                                      ) : (
                                          <IconButton onClick={() => handleSave()} size="small">
                                            <CheckmarkIcon/>
                                          </IconButton>
                                      )}
                                      <IconButton onClick={() => handleCancel()} size="small">
                                        <CloseIcon/>
                                      </IconButton>
                                    </div>
                                )
                            }
                          </>
                      ) : (
                          <div style={{marginBottom: mb}}>
                            <Tooltip title={copied ? t('DASHBOARD.INPUT_COPIED') : t('DASHBOARD.INPUT_COPY')}>
                              <IconButton onClick={() => handleCopy(value)} size="small">
                                <CopyIcon/>
                              </IconButton>
                            </Tooltip>
                            {isEditable &&
                                <Tooltip title={t('DASHBOARD.INPUT_PASTE')}>
                                  <IconButton onClick={handlePaste} size="small">
                                    <PasteIcon/>
                                  </IconButton>
                                </Tooltip>
                            }
                          </div>
                      )}
                    </InputAdornment>
                )
              }
            }}
        />
      </>
  );
};

export default InputField;
