// ClaimHeader.tsx
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import claimBackground from "../../assets/gradient.jpg";
import useAuthenticatedFetch from "../../auth/authenticated";
import "../../css/App.css";
import { common } from "../../theme/colors";
import { FEEDBACK } from "../../models/Feedback";
import ResolveModal from "./modals/ResolveModal";
import WaitingModal from "./modals/WaitingModal";
import AutomateModal from "./modals/AutomateModal";
import { keyboardArrowIconStyle } from "../../theme/theme";
import theme from "../../theme/MuiTheme";
import { Claim } from "../../models/Claim";
import Feedback from "../ui/Feedback";

export const ClaimWrapper = styled(Box)(({ theme }) => ({
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    display: "inline-flex",
  },
}));

export const CustomHeaderButton = styled(Button)(() => ({
  border: "2px solid",
  color: common["white"],
  borderColor: common["white"],
  backgroundColor: "transparent",
  borderRadius: 0,
  fontWeight: "700",
  "&:hover": {
    background: "rgba(255, 255, 255, .2)",
    border: "2px solid",
    color: common["white"],
    borderColor: common["white"],
  },
  "&:disabled": {
    opacity: "70%",
    color: common["white"],
    borderColor: common["white"],
    border: "2px solid",
    backgroundColor: "transparent"
  }
}));

interface ClaimHeaderProps {
  claim: Claim;
  updateClaim: Function;
  schema: any;
  id: string;
}

const ClaimHeader: React.FC<ClaimHeaderProps> = ({ claim, updateClaim, schema, id }) => {
  //Dropdown arrow indicator
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  // request pending indicator
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // feedback
  const [showFeedback, setShowFeedback] = useState<FEEDBACK>(FEEDBACK.NONE);
  // resolve modal
  const [isResolveModalOpen, setIsResolveModalOpen] = useState<boolean>(false);
  // waiting modal
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  // automate modal
  const [isAutomateModalOpen, setIsAutomateModalOpen] = useState(false);

  // hooks
  const { t } = useTranslation();
  const getAccessHeader = useAuthenticatedFetch();

  /**
   * Callback for saving the changes selected in Waiting Modal
   * @param claimStatusId
   * @param wakeupTime
   * @param processType
   */
  const handleWaitingSave = async (claimStatusId: string, wakeupTime: string, processType: string) => {
    try {
      const response = await fetch(`/api/claims/${id}`, {
        method: "PUT",
        headers: await getAccessHeader(),
        body: JSON.stringify({
          "claim_status_id": claimStatusId,
          "wakeup_time": wakeupTime,
          "automated": processType === 'auto'
        }),
      });

      const result = await response.json();

      if (result.success) {
        setShowFeedback(FEEDBACK.SUCCESS);
        // show feedback for a second and reload the page to get the next claim from queue
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setShowFeedback(FEEDBACK.ERROR);

        return result.error;
      }
    } catch (error: any) {
      console.log("Error:", error.message);
      setShowFeedback(FEEDBACK.ERROR);

      return `Error`;
    }
  };

  /**
   * Callback for saving the claim status change
   * @param updatedValue
   */
  const saveStatusChange = async (updatedValue: string) => {
    try {
      setIsLoading(true);

      const response = await fetch(`/api/claims/${id}`, {
        method: "PUT",
        headers: await getAccessHeader(),
        body: JSON.stringify({ "claim_status_id": updatedValue }),
      });

      const result = await response.json();

      if (result.success) {
        // update UI
        updateClaim((currentClaim) => ({
          ...currentClaim,
          "claim_status_id": updatedValue,
        }));

        setIsLoading(false);
        setShowFeedback(FEEDBACK.SUCCESS);
      } else {
        setIsLoading(false);
        setShowFeedback(FEEDBACK.ERROR);

        return result.error;
      }
    } catch (error: any) {
      console.log("Error:", error.message);
      setIsLoading(false);
      setShowFeedback(FEEDBACK.ERROR);

      return `Error`;
    }
  };

  /**
   * Callback for saving the changes selected in the Automate Modal
   * @param claimStatusId
   */
  const handleAutomateClaim = async (claimStatusId: string) => {
    try {
      const response = await fetch(`/api/claims/${id}`, {
        method: "PUT",
        headers: await getAccessHeader(),
        body: JSON.stringify({
          "claim_status_id": claimStatusId,
          "automated": true
        }),
      });

      const result = await response.json();

      if (result.success) {
        setShowFeedback(FEEDBACK.SUCCESS);
        // show feedback for a second and reload the page to get the next claim from queue
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setShowFeedback(FEEDBACK.ERROR);

        return result.error;
      }
    } catch (error: any) {
      console.log("Error:", error.message);
      setShowFeedback(FEEDBACK.ERROR);

      return `Error`;
    }
  }

  if (!claim) {
    return (
      <Typography variant="h6">{t("DASHBOARD.HEADER.NO_DATA")}</Typography>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Box
          sx={{
            pt: 2,
            pb: 2,
            backgroundImage: `url('${claimBackground}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex"
          }}
          className="wrapper"
        >
          <ClaimWrapper
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "column-reverse",
                [theme.breakpoints.up("xl")]: {
                  flexDirection: "row",
                  alignItems: "center"
                },
              }}
            >
              <Box sx={{display: "flex", flexDirection: "row" }}>
                <Typography
                    variant="h1"
                    sx={{
                      color: common["white"],
                      mr: 1,
                      textWrap: "nowrap"
                    }}
                >
                  Claim ID{" "}
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                      color: common["white"],
                      mr: 3,
                    }}
                >
                  {claim.client?.abbreviation}-{claim.claim_id_tenant}
                </Typography>
              </Box>

              <FormControl variant="standard">
                <Select
                  variant="standard"
                  open={openDropdown}
                  onOpen={() => setOpenDropdown(true)}
                  onClose={() => setOpenDropdown(false)}
                  sx={{ padding: "0" }}
                  disabled={isLoading}
                  disableUnderline
                  id="status"
                  value={claim.claim_status_id}
                  onChange={(event) =>
                    saveStatusChange(event.target.value)
                  }
                  label={t("GENERAL.SELECT_OPTION")}
                  inputProps={{ IconComponent: () => null }}
                  renderValue={() => (
                    <Chip
                      style={{
                        border: "2px dashed",
                        color: common["white"],
                        borderColor: common["white"],
                        paddingTop: "2px",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                      label={
                        <>
                          {isLoading ? (
                            <CircularProgress
                              size={20}
                              style={{ marginTop: "3px" }}
                            />
                          ) : (
                            <>
                              {t(
                                "STATUS." +
                                schema.claim_status_id.constraints.options[claim.claim_status_id]
                              )}
                              {openDropdown ? (
                                <KeyboardArrowUpIcon
                                  sx={keyboardArrowIconStyle}
                                  fontSize="large"
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  sx={keyboardArrowIconStyle}
                                  fontSize="large"
                                />
                              )}
                            </>
                          )}
                        </>
                      }
                      variant="outlined"
                    />
                  )}
                >
                  {schema.claim_status_id.constraints.nullable && (
                    <MenuItem value="unknown">
                      {t("DASHBOARD.DETAILS.null")}
                    </MenuItem>
                  )}
                {Object.entries(
                  schema.claim_status_id.constraints.options as {
                    [key: string]: string;
                  },
                ).sort(([, a], [, b]) => a.localeCompare(b))
                 .map(([id, value]) => (
                  <MenuItem key={id} value={id}>
                    {t("STATUS." + value)}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              {/* CTA´s */}
              <CustomHeaderButton
                variant="outlined"
                sx={{ mr: 2 }}
                size="large"
                onClick={() => setIsWaitingModalOpen(true)}
              >
                {t("DASHBOARD.HEADER.WAITING")}
              </CustomHeaderButton>

              {/*<CustomHeaderButton*/}
              {/*    variant="outlined"*/}
              {/*    sx={{ mr: 2 }}*/}
              {/*    size="large"*/}
              {/*    onClick={() => setIsAutomateModalOpen(true)}*/}
              {/*>*/}
              {/*  {t("DASHBOARD.HEADER.AUTOMATE")}*/}
              {/*</CustomHeaderButton>*/}

              <CustomHeaderButton
                variant="outlined"
                size="large"
                disabled={!claim.category}
                onClick={() => setIsResolveModalOpen(true)}
              >
                {t("DASHBOARD.HEADER.RESOLVE")}
              </CustomHeaderButton>

              {/* Modals */}
              <ResolveModal
                isOpen={isResolveModalOpen}
                onClose={() => setIsResolveModalOpen(false)}
                schema={schema}
                claim={claim}
              />
              <WaitingModal
                isOpen={isWaitingModalOpen}
                onClose={() => setIsWaitingModalOpen(false)}
                onSave={handleWaitingSave}
                waitingStatusSchema={schema.claim_status_id.constraints.options}
              />
              <AutomateModal
                isOpen={isAutomateModalOpen}
                onClose={() => setIsAutomateModalOpen(false)}
                onSave={handleAutomateClaim}
                claimStatusSchema={schema.claim_status_id.constraints.options}
                currentStatus={claim.claim_status_id}
              />
            </Box>
          </ClaimWrapper>
        </Box>
      </Box>

      {/* Request Feedback */}
      <Feedback type={showFeedback} onClose={setShowFeedback}></Feedback>
    </Box>
  );
};

export default ClaimHeader;
